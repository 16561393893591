<template>
  <Dialog :visible="containerVisible" :style="{width: '600px'}" :header="$t('user.dialog.form_header')" :modal="true" class="p-fluid" @update:visible="updateVisible">
    <div class="field grid">
      <label for="orgType" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.org_type')}}</label>
      <div class="col-12 md:col-10">
        <Dropdown id="org_type" v-model="selectedPartnerType" :options="partnerTypes" optionLabel="label" :placeholder="$t('user.select_org_type')" @change="onChangeOrgType($event)">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
            </div>
            <span v-else>
              {{slotProps.placeholder}}
            </span>
          </template>
        </Dropdown>
        <small class="p-invalid p-error" v-if="showErrors && !currentEntity.orgType">{{$t('user.required_org_type')}}</small>
      </div>
    </div>
    <div class="field grid" v-if="currentEntity.orgType=='owner'">
      <label for="roles" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.roles')}}</label>
      <div class="col-12 md:col-10">
        <Dropdown id="roles" v-model="selectedRole" :options="roles" optionLabel="label" :placeholder="$t('user.select_roles')" @change="onChangeRole($event)">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.value">
              <span :class="'product-badge status-' + slotProps.value.value">{{slotProps.value.label}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.value">
              <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{slotProps.value}}</span>
            </div>
            <span v-else>
              {{slotProps.placeholder}}
            </span>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="field grid" v-else>
      <label for="organization" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.organization')}}</label>
      <div class="col-12 md:col-10">
        <Dropdown id="organization" v-model="selectedOrganization" :options="organizations" optionLabel="name" :placeholder="$t('user.select_organization')" @change="onChangeOrganization($event)">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.name">
              <span>{{slotProps.value.name}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.name">
              <span>{{slotProps.value.code}}</span>
            </div>
            <span v-else>
              {{slotProps.placeholder}}
            </span>
          </template>
        </Dropdown>
        <small class="p-invalid p-error" v-if="showErrors && !currentEntity.organization">{{$t('user.required_organization')}}</small>
      </div>
    </div>

    <div class="field grid">
      <label for="email" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.email')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="email" v-model.trim="currentEntity.email" required="true" :disabled="currentEntity.orgType=='agent'" autofocus :class="{'p-invalid': submitted && !currentEntity.email}" />
        <small class="p-invalid p-error" v-if="showErrors && !currentEntity.email">{{$t('user.required_email')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="fullName" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.fullName')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="fullName" v-model.trim="currentEntity.fullName" required="true" autofocus :class="{'p-invalid': submitted && !currentEntity.fullName}" />
        <small class="p-invalid p-error" v-if="showErrors && !currentEntity.fullName">{{$t('user.required_full_name')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="password" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.password')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="password" v-model.trim="currentEntity.password" required="true" autofocus :class="{'p-invalid': submitted && !currentEntity.password}" type="password"/>
        <small class="p-invalid p-error" v-if="showErrors && !currentEntity.password && !currentEntity.id">{{$t('user.required_password')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="password2" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.password2')}}</label>
      <div class="col-12 md:col-10">
        <InputText id="password2" v-model.trim="currentEntity.password2" required="true" autofocus :class="{'p-invalid': submitted && !currentEntity.password2}" type="password"/>
        <small class="p-invalid p-error" v-if="showErrors && !currentEntity.password2 && !currentEntity.id && currentEntity.password2 != currentEntity.password">{{$t('user.required_password2')}}</small>
        <small class="p-invalid p-error" v-if="showErrors && currentEntity.password2 != currentEntity.password">{{$t('user.password2_not_matched')}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="description" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('user.description')}}</label>
      <div class="col-12 md:col-10">
        <Textarea id="description" v-model="currentEntity.description" required="false" rows="3" cols="20" />
      </div>
    </div>
    <template #footer>
      <Button :label="$t('button.cancel')" icon="pi pi-times" class="p-button-text" @click="onCancel"/>
      <Button :label="$t('button.save')" icon="pi pi-check" class="p-button-text" @click="onSave" />
    </template>
  </Dialog>
</template>

<script>
import ConfigService from '@/service/ConfigService';
import PartnerService from '@/service/PartnerService';
import form from '@/mixins/form'
export default {
  name:'form-user',
  mixins: [form],
  props: {
    entity: {
      type: Object,
      default() {
        // default function receives the raw props object as argument
        return {  }
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits:['cancel','save','hide'],
  data() {
    return {
      submitted: false,
      showErrors: false,
      currentEntity: this.entity,
      containerVisible: this.visible,
      roles: [],
      partnerTypes: [],
      organizations: [],
      selectedPartnerType:null,
      selectedRole: null,
      selectedOrganization: null
    }
  },
  methods: {
    //Get organizations list base on selected an orgType
    onChangeRole(event) {
      this.currentEntity.roles = event.value.value || null
    },
    onChangeOrgType(event) {
      this.currentEntity.orgType = event.value.value
      this._loadOrganizations();
    },
    _loadOrganizations() {
      if (this.currentEntity.orgType == 'agent') {
        this.organizations = this.agents;
      }
    },
    onChangeOrganization(event) {
      this.currentEntity.organization = event.value.code;
      this.currentEntity.email = event.value.contactEmail;
      this.currentEntity.fullName = event.value.contactName;
      this.currentEntity.roles = ConfigService.getAdminRole();
    },
    onCancel() {
      this._reset();
      this.$emit('cancel');
    },
    onSave() {
      if(!this.submitted) {
        this.showErrors = true;
        this.errors = this.validate(this.currentEntity, 'user');
        if(Object.keys(this.errors).length == 0) {
          //validate password
          if (this.currentEntity.password2 == this.currentEntity.password) {
            this.submitted = true;
            this.$emit('save', this.currentEntity);
          }
        }
      }
    },
    updateVisible(visible) {
      this.showErrors = false;
      this.containerVisible = visible;
      this.currentEntity = {};
      this.$emit('hide')
    },
    _reset() {
      this.showErrors = false;
      this.containerVisible = false;
      this.currentEntity = {};
    }
  },
  mounted() {
    PartnerService.getAgents().then(response => {
      this.agents = response;
    });
    this.partnerTypes = ConfigService.getPartnerTypes().map(type => ({
      label: this.$t('configs.partner_type.' + type),
      value: type
    }));

    this.roles = ConfigService.getRoles().map((role) => ({
        label: this.$t('configs.roles.' + role),
        value: role
      }));
  },
  updated() {
    this.submitted = false;
    this.currentEntity = this.entity;
    this.containerVisible = this.visible;
    this.selectedPartnerType = null;
    for(let i = 0; i < this.partnerTypes.length; i++) {
      if(this.currentEntity.orgType == this.partnerTypes[i].value) {
        this.selectedPartnerType = this.partnerTypes[i];
        break;
      }
    }

    this._loadOrganizations();
    this.selectedOrganization = null;
    for(let i = 0; i < this.organizations.length; i++) {
      if(this.organizations[i].code == this.currentEntity.organization) {
        this.selectedOrganization = this.organizations[i];
      }
    }
  },
}
</script>
