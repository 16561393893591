import http, {Service} from './common'
const resource = 'configs'
class ConfigService extends Service {
  getAdminRole() {
    return 'admin';
  }
  getRoles() {
    return ['admin','delivery','store','user'];
  }
  getPartnerTypes() {
    return ['owner','agent','delivery'];
  }
  getSelectedItem(list, entity, field) {
    if (list && entity && field) {
      for (var i = 0; i < list.length; i++) {
        if(list[i].value == entity[field]) {
          return list[i];
        }
      }
    }
  }

  findByName(name) {
    return http.get(`/${resource}?name=${name}`)
  }
}

export default new ConfigService(resource)
