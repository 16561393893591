const mixin_form = {
  mounted() {
    if(this.meta && this.meta.entityName) {
        this.service = this.$api.createService(this.meta.entityName);
    }
  },
  methods: {
    validate(formValues, sEntity) {
      let errors = {};
      for(let fName in this.$refs) {
        let field = this.$refs[fName];
        if (field && field.$attrs.required && (!formValues || !formValues[fName])) {
          errors[fName] = this.$t(sEntity + '.' + fName) + ' ' +  this.$t('common.required');
          if(!field.$el.classList.contains("p-invalid")) {
            field.$el.classList.add("p-invalid");
          }
        } else {
          delete errors[fName];
        }
      }
      return errors;
    },
    initMetadata(metadata) {
      this.formMeta = metadata;
    },
    onSave(){
      let errors = this.validate(this.formValues, this.meta.entityName);
      if (Object.keys(errors).length == 0) {
        if (typeof this.validateForm == 'function') {
          errors = this.validateForm(this.formValues, this.meta.entityName);
        }
      }
      this.errors = errors;
      if (Object.keys(errors).length == 0 && this.service) {
        this.$emit('save', this.formValues);
        /*
        return this.service.save(this.formValues).then(res => {
          if (typeof this.afterSave == 'function') {
            this.afterSave(res);
          }
        });
        */
      }
    },
    onReset(){
      //console.log(this.formValues);
      //console.log("onSave")
    }
  }
}

export default mixin_form;
