<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <CrudToolbar
          @create="openNew"
          @delete="confirmDeleteSelected"
          @export="exportCSV($event)"
        />
        <DataTable
          ref="dt"
          dataKey="id"
          :showGridlines="true"
          :value="entities"
          v-model:selection="selectedEntity"
          filterDisplay="menu"
          v-model:filters="filters"
          :globalFilterFields="[
            'email',
            'fullName',
            'description',
            'orgType',
            'organization',
          ]"
          :paginator="true"
          :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <!--
							<h5 class="m-0">{{$t('user.title_list')}}</h5>
							-->
              <Button
                type="button"
                icon="pi pi-filter-slash"
                :label="$t('common.clear')"
                class="p-button-outlined"
                @click="clearFilters()"
              />
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Search..."
                />
              </span>
            </div>
          </template>

          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
          <Column
            headerStyle="width:10%; min-width:10rem;"
            bodyStyle="text-align:center;"
          >
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editEntity(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="openConfirmDialog(slotProps.data)"
              />
            </template>
          </Column>
          <Column
            field="email"
            :header="$t('user.email')"
            :sortable="true"
            headerStyle="width:15%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("user.email") }}</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column
            field="fullName"
            :header="$t('user.fullName')"
            :sortable="true"
            headerStyle="width:12%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("user.fullName") }}</span>
              {{ slotProps.data.fullName }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                :placeholder="
                  $t('common.search_by_field', [$t('user.fullName')])
                "
              />
            </template>
            <!--
            <template #filterclear="{filterCallback}">
                <Button type="button" icon="pi pi-times" @click="filterCallback()" class="p-button-secondary"></Button>
            </template>
            <template #filterapply="{filterCallback}">
                <Button type="button" icon="pi pi-check" @click="filterCallback()" class="p-button-success"></Button>
            </template>
            <template #filterfooter>
                <div class="px-3 pt-0 pb-3 text-center font-bold">Customized Buttons</div>
            </template>
						-->
          </Column>
          <Column
            field="roles"
            :header="$t('user.roles')"
            :sortable="true"
            headerStyle="width:10%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("user.roles") }}</span>
              {{ slotProps.data.roles }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                :placeholder="$t('common.search_by_field', [$t('user.roles')])"
              />
            </template>
          </Column>
          <Column
            field="orgType"
            :header="$t('user.org_type')"
            :sortable="true"
            headerStyle="width:10%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("user.org_type") }}</span>
              {{ slotProps.data.orgType }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                :placeholder="
                  $t('common.search_by_field', [$t('user.org_type')])
                "
              />
            </template>
          </Column>
          <Column
            field="organization"
            :header="$t('user.organization')"
            :sortable="true"
            headerStyle="width:10%; min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("user.organization") }}</span>
              {{ slotProps.data.organization }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                :placeholder="
                  $t('common.search_by_field', [$t('user.organization')])
                "
              />
            </template>
          </Column>
          <Column
            field="lastLogined"
            dataType="String"
            :header="$t('user.lastLogined')"
            :sortable="true"
            headerStyle="width:10%; min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("user.lastLogined") }}</span>
              {{ this.formatDate(slotProps.data.lastLogined) }}
            </template>
          </Column>
          <Column
            field="description"
            :header="$t('user.description')"
            :sortable="true"
            headerStyle="min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("user.description") }}</span>
              {{ slotProps.data.description }}
            </template>
          </Column>
          <!--
					<Column header="Image" headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">{{$t('receipt.image')}}</span>
							<img :src="'images/product/' + slotProps.data.image" :alt="slotProps.data.image" class="shadow-2" width="100" />
						</template>
					</Column>
					-->
        </DataTable>
        <DialogFormUser
          :entity="currentEntity"
          :visible="entityDialog"
          @hide="entityDialog = false"
          @cancel="entityDialog = false"
          @save="saveEntity($event)"
        />

        <!-- <dialog-confirm
          :visible="deleteEntityDialog"
          :header="$t('user.confirm_delete')"
          content="Dialog content"
          @hide="deleteEntityDialog = false"
          @reject="deleteEntityDialog = false"
          @accept="() => this.deleteEntity()"
        >
          <template #content>
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="currentEntity"
                >Are you sure you want to delete <b>{{ currentEntity.email }}</b
                >?</span
              >
            </div>
          </template>
        </dialog-confirm> -->
      </div>
    </div>
  </div>
</template>

<script>
//import {FilterMatchMode, FilterOperator} from 'primevue/api';
import UserService from "@/service/UserService";
import DialogConfirm from "@/pages/common/DialogConfirm";
import CrudToolbar from "@/pages/common/CrudToolbar";
import DialogFormUser from "./DialogFormUser";
import filter from "@/mixins/filter";
import formatter from "@/utils/formatter";
import Consts from "@/utils/consts";
export default {
  components: { CrudToolbar, DialogConfirm, DialogFormUser },
  mixins: [filter],
  data() {
    return {
      entities: null,
      selectedEntity: null,
      entityDialog: false,
      deleteEntityDialog: false,
      deleteEntitiesDialog: false,
      currentEntity: {},
      selectedEntities: null,
      filters: {},
    };
  },
  created() {
    this.initGlobalFilter(this.filters);
  },
  mounted() {
    this.initColumnFilters(this.$refs.dt, this.filters);
    UserService.getAll().then((data) => {
      this.entities = data;
    });
  },
  methods: {
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    formatDate(jsDate) {
      return formatter.formatDate(jsDate, Consts.FORMAT_DATE_US);
    },
    openNew() {
      this.currentEntity = {};
      this.entityDialog = true;
    },
    hideDialog() {
      this.entityDialog = false;
      this.submitted = false;
    },
    saveEntity(entity) {
      if (entity.email.trim()) {
        if (entity.id) {
          this.entities[this.findIndexById(entity.id)] = entity;
          UserService.update(entity.id, entity).then((response) => {
            //console.log(response);
            this.$toast.add({
              severity: "success",
              summary: "Successful",
              detail: this.$t("user.updated"),
              life: 3000,
            });
            this.currentEntity = response.data;
            this.entityDialog = false;
          });
        } else {
          UserService.create(entity).then((response) => {
            this.currentEntity = response.data;
            this.entities.push(response.data);
            this.$toast.add({
              severity: "success",
              summary: "Successful",
              detail: this.$t("user.created"),
              life: 3000,
            });
            this.entityDialog = false;
          });
        }
        this.selectedEntity = {};
      }
    },
    editEntity(entity) {
      this.currentEntity = { ...entity };
      this.entityDialog = true;
    },
    openConfirmDialog(entity) {
      console.log("Open Confirm dialog");
      this.currentEntity = entity;
      this.$confirm.require({
        message: `Are you sure you want to delete the user ${entity.email}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteEntity();
        },
        reject: () => {},
      });
    },
    // confirmDeleteEntity(entity) {
    //   this.currentEntity = entity;
    //   this.deleteEntityDialog = true;
    // },
    deleteEntity() {
      //   this.deleteEntityDialog = false;
      UserService.delete(this.currentEntity.id).then(() => {
        this.entities = this.entities.filter(
          (val) => val.id !== this.currentEntity.id
        );
        this.currentEntity = {};
        this.$toast.add({
          severity: "success",
          summary: "Successful",
          detail: this.$t("user.deleted"),
          life: 3000,
        });
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.entities.length; i++) {
        if (this.entities[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteEntitiesDialog = true;
    },
    deleteSelectedEntities() {
      this.entities = this.entities.filter(
        (val) => !this.selectedEntities.includes(val)
      );
      this.deleteEntitiesDialog = false;
      this.selectedEntities = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: this.$t("user.multiple_deleted"),
        life: 3000,
      });
    },
    /*
		initFilters() {
			this.filters = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'fullName' : {operator: FilterOperator.AND, constraints: [{value: null, matchMode:FilterMatchMode.CONTAINS}]}
			};
		}
		*/
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
</style>
