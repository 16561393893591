<template>
  <Toolbar class="mb-4">
    <template v-slot:start>
      <div class="my-2">
        <Button :label="$t('button.create')" icon="pi pi-plus" class="p-button-success mr-2" @click="$emit('create')" v-model:disabled="disabledCreate"/>
        <Button :label="$t('button.delete')" icon="pi pi-trash" class="p-button-danger" @click="$emit('delete')" v-model:disabled="disabledDelete" />
      </div>
    </template>

    <template v-slot:end>
      <!--
      <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="$t('button.import')" chooseLabel="Import" class="mr-2 inline-block" />
      -->
      <Button :label="$t('button.export')" icon="pi pi-upload" class="p-button-primary" @click="$emit('export',$event)"  v-model:disabled="disabledExport"/>
    </template>
  </Toolbar>
</template>
<script>
export default {
  name:'crud-Toolbar',
  props: {
    creatable: {
      type: Boolean,
      default: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    exportable: {
      type: Boolean,
      default: true
    }
  },
  emits:['create','delete','export'],
  data() {
    return {
      disabledCreate: !this.creatable,
      disabledDelete: !this.deletable,
      disabledExport: !this.exportable
    }
  }
}
</script>
