const values = {
  FORMAT_DATE_US: "MM/dd/yyyy",
  FORMAT_DATE_TIME_US: "MM/dd/yyyy hh:mm:ss",
  FORMAT_DATE_VN: "dd/MM/yyyy",
  FORMAT_TIME: "hh:mm:ss",
  FORMAT_DATE_TIME_VN: "dd/MM/yyyy hh:mm:ss",
  ORGTYPE_OWNER: "owner",
  REMOTE_FEE_RATE: 20, //percentile
  POUND2KG: 0.453592,
  MAX_RECEIPT_ITEM_ROWS: 22,
  ROW_PER_PAGE_OPTIONS: [10, 50, 100, 200],
  DEFAULT_PAGE_ROWS: 10,
  MAX_WEIGHT_DIFF: 1, //Sai so 1 pound
  MAX_WEIGHT_DIFF_IN_PERCENTILE: 1, //Sai so in %
  DEFAULT_PACKAGE_NUMBER: 10,
  PACKGE_CODE_LENGTH: 3,
};

/*
const converter = {
  tokg: function(lbs) {
    return isNaN(lbs) ? 0: Math.round(lbs * values.POUND2KG * 100)/100;
  },

  tolbs: function(val) {
    return isNaN(val) ? 0: Math.round(val / values.POUND2KG * 100)/100;
  }
}
export {converter};
*/
export default values;

/*
function myFunction() {
  var copyText = document.getElementById("myInput");

  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

  navigator.clipboard.writeText(copyText.value);

  alert("Copied the text: " + copyText.value);
}
*/
