import http, {Service} from './common'
const resource = 'partners'
class PartnerService extends Service {

  getAgents() {
    return http.get(`/${resource}/?partnerType=agent`).then((response) => {
      return response.data || [];
    })
  }

  findByName(partner) {
    return http.get(`/${resource}?name=${partner}`)
  }
}

export default new PartnerService(resource)
