<template>
  <Dialog :visible="containerVisible" :style="{width: '450px'}" :header="header" :modal="true" @update:visible="updateVisible">
    <slot name="content">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="content">{{content}}</span>
      </div>
    </slot>
    <template #footer>
      <Button :label="$t('button.no')" icon="pi pi-times" class="p-button-text" @click="onReject"/>
      <Button :label="$t('button.yes')" icon="pi pi-check" class="p-button-text" @click="$emit('accept')" />
    </template>
  </Dialog>
</template>
<script>
export default {
  name: 'dialog-confirm',
  props: {
    content: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['reject','accept','hide'],
  data() {
    return {
      containerVisible: this.visible
    }
  },
  updated() {
      if (this.visible) {
          this.containerVisible = this.visible;
      }
  },
  methods: {
    onReject() {
      this.containerVisible = false
      this.$emit('reject')
    },
    updateVisible(visible) {
      this.containerVisible = visible;
      this.$emit('hide')
    }
  }
}
</script>
